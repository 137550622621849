<template>
    <v-stepper v-model="step" id="stepper" class="no-transition">
        <v-stepper-header>
            <template v-for="({ position, label, editable }, index) in steps">
                <v-stepper-step
                    :key="index"
                    :complete="step > position"
                    :step="position"
                    :editable="editable && step > position"
                    >{{ label }}
                </v-stepper-step>
            </template>
        </v-stepper-header>

        <v-stepper-items>
            <template v-for="({ position, component }, index) in steps">
                <v-stepper-content
                    :key="`content-${index}`"
                    :step="position"
                    class="pa-2"
                    elevation-0
                >
                    <component
                        :is="component"
                        :step="position"
                        :applicant="applicant"
                        :application="application"
                        :role="role"
                        :current-step="step"
                        @next="next"
                        @previous="previous"
                        @update:applicant="updateApplicant"
                        @submit:applicant="submitApplicant"
                    ></component>
                </v-stepper-content>
            </template>
        </v-stepper-items>
    </v-stepper>
</template>
<script>
import { mapActions } from "vuex";

export default {
    components: {
        ApplicationSetup: () => import("./Setup/ApplicationSetup"),
        ApplicantApply: () => import("../Apply/ApplicantApply"),
        ApplicantAuthorize: () => import("../Apply/ApplicantAuthorize"),
        ApplicantCheckout: () => import("../Apply/ApplicantCheckout"),
        ApplicantSubmit: () => import("../Apply/ApplicantSubmit"),
        ApplicantConfirmation: () => import("../Apply/ApplicantConfirmation"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        application: {
            type: Object,
            required: true,
        },
        role: {
            type: String,
            required: true,
            default: "",
        },
    },
    data() {
        return {
            step: 1, // starting step
        };
    },
    computed: {
        // steps are recalculated when we mount the app, we guarante mount with a dynamic key in the parent
        steps: {
            immediate: true,
            get() {
                let steps = [
                    // We always have a starting step
                    {
                        position: 1,
                        label: this.$t("applications.start"),
                        component: "application-setup",
                        editable: false,
                    },
                    // We always have an edit step to apply
                    {
                        position: 2,
                        label: this.$t("applications.apply"),
                        component: "applicant-apply",
                        editable: false,
                    },
                ];
                // conditional steps based on sections
                if (
                    this.$_.get(this.applicant, "sections.authorization", true) // need a signature step
                ) {
                    steps.push({
                        position: steps.length + 1,
                        label: this.$t("applications.authorize"),
                        component: "applicant-authorize",
                    });
                }
                if (this.$_.get(this.applicant, "sections.payment", true)) {
                    steps.push({
                        position: steps.length + 1,
                        label: this.$t("applications.payment"),
                        component: "applicant-checkout",
                    });
                }
                // Only Review & Submit if no payment screen
                if (!this.$_.get(this.applicant, "sections.payment", true)) {
                    steps.push({
                        position: steps.length + 1,
                        label: this.$t("applications.submit"),
                        component: "applicant-submit",
                    });
                }
                // We always have a confirmation
                steps.push({
                    position: steps.length + 1,
                    label: this.$t("applications.confirmation"),
                    component: "applicant-confirmation",
                });

                return steps;
            },
        },
    },
    watch: {},
    methods: {
        ...mapActions("Applications", ["updateApplicant", "submitApplicant"]),
        async next(step) {
            this.step = step;
            this.$nextTick(() => {
                this.$vuetify.goTo("#stepper", {
                    easing: "easeInOutCubic",
                    offset: 100,
                    duration: 450,
                });
            });
        },
        previous(step) {
            this.step = step;
            this.$nextTick(() => {
                this.$vuetify.goTo("#stepper", {
                    easing: "easeInOutCubic",
                    offset: 100,
                    duration: 450,
                });
            });
        },
    },
};
</script>

<style >
.no-transition .v-stepper__content {
    transition: none;
}
</style>