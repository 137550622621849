var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-stepper",
    {
      staticClass: "no-transition",
      attrs: { id: "stepper" },
      model: {
        value: _vm.step,
        callback: function($$v) {
          _vm.step = $$v
        },
        expression: "step"
      }
    },
    [
      _c(
        "v-stepper-header",
        [
          _vm._l(_vm.steps, function(ref, index) {
            var position = ref.position
            var label = ref.label
            var editable = ref.editable
            return [
              _c(
                "v-stepper-step",
                {
                  key: index,
                  attrs: {
                    complete: _vm.step > position,
                    step: position,
                    editable: editable && _vm.step > position
                  }
                },
                [_vm._v(_vm._s(label) + " ")]
              )
            ]
          })
        ],
        2
      ),
      _c(
        "v-stepper-items",
        [
          _vm._l(_vm.steps, function(ref, index) {
            var position = ref.position
            var component = ref.component
            return [
              _c(
                "v-stepper-content",
                {
                  key: "content-" + index,
                  staticClass: "pa-2",
                  attrs: { step: position, "elevation-0": "" }
                },
                [
                  _c(component, {
                    tag: "component",
                    attrs: {
                      step: position,
                      applicant: _vm.applicant,
                      application: _vm.application,
                      role: _vm.role,
                      "current-step": _vm.step
                    },
                    on: {
                      next: _vm.next,
                      previous: _vm.previous,
                      "update:applicant": _vm.updateApplicant,
                      "submit:applicant": _vm.submitApplicant
                    }
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }